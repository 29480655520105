<template>
    <div>
        <HeaderOne />
        <!-- main area start -->
        <main>
            <!-- breadcrumb area start -->
            <Breadcrumb title="blog detail" />
            <!-- breadcrumb area end -->
            <!-- merox blog area start -->
            <div id="merox-blog-area" class="merox-blog-area mt-100 mb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 pr-30">
                            <div class="merox-blog-details-wraper">
                                <div class="blog-details-content">
                                    <div class="blog-details-img">
                                        <img :src="img" alt="img" />
                                    </div>
                                    <div class="blog-date">{{ date }}</div>
                                    <h4>{{ title }}</h4>
                                    <p>{{ para }}</p>
                                    <figure>
                                        <img :src="img2" alt="img" />
                                    </figure>
                                    <h3>{{ title2 }}</h3>
                                    <p>{{ para2 }}</p>
                                    <p>{{ para3 }}</p>
                                    <blockquote class="block-quote" v-html="quote">
                                    </blockquote>
                                    <p>{{ para4 }}</p>
                                    <p>{{ para5 }}</p>

                                </div>
                                <!-- tags & share area start -->
                                <div class="blog-post-tag">
                                    <span>post tags:</span>
                                    <a href="#">awesome</a>
                                    <a href="#">template</a>
                                    <a href="#">ui/ux</a>
                                </div>
                                <!-- tags & share area end -->


                                <!-- recent comments area start -->
                                <div class="recent-comments-area mt-50">
                                    <div class="post-heading">
                                        <h3>3 Comments</h3>
                                    </div>
                                    <div class="latest-comments">
                                        <ul>
                                            <li>
                                                <div class="comments-box">
                                                    <div class="comments-avatar">
                                                        <img src="@/assets/img/blog/comments1.png" alt="">
                                                    </div>
                                                    <div class="comments-text">
                                                        <div class="avatar-name-date">
                                                            <h5>merox</h5>
                                                            <span>Dec 08 2022</span>
                                                        </div>
                                                        <p>industry. Lorem Ipsum has been the industry's standard dummy
                                                            text ever since t he 1500s, when an unknown</p>
                                                        <a href="#">Reply</a>
                                                    </div>
                                                </div>
                                                <ul class="comments-reply">
                                                    <li>
                                                        <div class="comments-box">
                                                            <div class="comments-avatar">
                                                                <img src="@/assets/img/blog/comments2.png" alt="">
                                                            </div>
                                                            <div class="comments-text">
                                                                <div class="avatar-name-date">
                                                                    <h5>Amron Morg</h5>
                                                                    <span>Dec 08 2022</span>
                                                                </div>
                                                                <p>industry. Lorem Ipsum has been the industry's
                                                                    standard dummy text ever since t he</p> <a
                                                                    href="#">Reply <i
                                                                        class="fa fa-long-arrow-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div class="comments-box">
                                                    <div class="comments-avatar">
                                                        <img src="@/assets/img/blog/comments1.png" alt="">
                                                    </div>
                                                    <div class="comments-text">
                                                        <div class="avatar-name-date">
                                                            <h5>merox</h5>
                                                            <span>Dec 08 2022</span>
                                                        </div>
                                                        <p>industry. Lorem Ipsum has been the industry's standard dummy
                                                            text ever since t he 1500s, when an unknown</p> <a
                                                            href="#">Reply</a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- recent comments area end -->

                                <!-- comments form area start -->
                                <div class="post-comments-form-area">
                                    <div class="post-heading">
                                        <h3>leave a reply</h3>
                                    </div>
                                    <div action="#" class="post-comments-form">
                                        <div class="row">
                                            <div class="col-xl-6">
                                                <input type="text" class="form-control" placeholder="Name *">
                                            </div>
                                            <div class="col-xl-6">
                                                <input type="email" class="form-control" placeholder="E-mail *">
                                            </div>
                                            <div class="col-xl-12">
                                                <input type="text" class="form-control" placeholder="Subject">
                                            </div>
                                            <div class="col-xl-12">
                                                <textarea name="comments" class="form-control" cols="30" rows="10"
                                                    placeholder="Your Comment"></textarea>
                                            </div>

                                            <div class="col-xl-12">
                                                <button class="btn btn-type-1" type="submit">post comment</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- comments form area end -->

                            </div>
                        </div>

                        <Sidebar />
                    </div>
                </div>
            </div>
            <!-- merox blog area end -->
            <FooterOne />
        </main>
        <!-- main area end -->
        <!-- scrolltop button -->
        <a class="material-scrolltop" href="#"></a>
    </div>
</template>

<script>
import img1 from '@/assets/img/blog/1.jpg'
import img2 from '@/assets/img/blog/details-1.jpg'
import Breadcrumb from "../Breadcrumb.vue";
import Sidebar from "../Sidebar.vue";
import HeaderOne from "../Header.vue";
import FooterOne from "../Footer.vue";
export default {
    name: "TeamPage",
    components: { HeaderOne, Sidebar, Breadcrumb, FooterOne },
    data() {
        return {
            img: img1,
            date: "14 NOV 2019",
            title: "in spring dumont opens mixed reality experience",
            para: "There are many variations of passages of Lorem Ipsum available, but the have and majority have suffered teration some form, by injected humour, and omised words which don't look even slightly believable. There are many of it passages of Lorem’s Ipsum available, but the",
            img2: img2,
            title2: "We’re Fortunate To Work With Worldwide Clients",
            para2: "It is a long established fact that a reader will be distracted by the readable of a page when this looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
            para3: "It is a long established fact that a reader will be distracted by the readable of a page when this looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
            quote: "<p>industry.has been the industry's standard dummy text ever since the 1500s, when an unk nown printer took a galley of type and and scrales</p><cite>mr. robul islam</cite>",
            para4: "It is a long established fact that a reader will be distracted by the readable of a page when this looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
            para5: "It is a long established fact that a reader will be distracted by the readable of a page when this looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        }
    },
    mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods: {
        topToBottom() {
            const result = document.querySelector('.material-scrolltop')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                result.classList.add("reveal");
            } else {
                result.classList.remove("reveal");
            }
        },
    },
};
</script>

<style>

</style>