<template>
    <!-- merox counter area start -->
    <div id="merox-counter-area" class="merox-counter-area mb-70 wow fadeInUp">
        <div class="container">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12" v-for="item in count_items" :key="item.id">
                    <div class="about-single-counter text-center">
                        <img :src="item.image" alt="icon" />
                        <ICountUp class="counter" :delay="item.delay" :endVal="item.endVal" :options="item.options" />
                        <h5>{{ item.title }}</h5>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- merox counter area end -->
</template>


<script>
import ICountUp from 'vue-countup-v2';
import img1 from "@/assets/img/counter/1.svg";
import img2 from "@/assets/img/counter/2.svg";
import img3 from "@/assets/img/counter/3.svg";
import img4 from "@/assets/img/counter/4.svg";
export default {
    name: 'CounterOne',
    components: {
        ICountUp
    },
    data() {
        return {
            count_items: [
                {
                    image: img1,
                    title: 'Download App',
                    delay: 1000,
                    endVal: 36780,
                    options: {
                        useEasing: true,
                        useGrouping: true,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                        suffix: ''
                    }
                },
                {
                    image: img2,
                    title: 'Easy Customize',
                    delay: 1000,
                    endVal: 6510,
                    options: {
                        useEasing: true,
                        useGrouping: true,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                        suffix: ''
                    }
                },
                {
                    image: img3,
                    title: 'Active Users',
                    delay: 1000,
                    endVal: 1246,
                    options: {
                        useEasing: true,
                        useGrouping: true,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                        suffix: ''
                    }
                },
                {
                    image: img4,
                    title: 'Positive Reviews',
                    delay: 1000,
                    endVal: 4316,
                    options: {
                        useEasing: true,
                        useGrouping: true,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                        suffix: ''
                    }
                },
            ],
        };
    },
};
</script>

<style>

</style>