<template>
    <div>
        <!-- preloaders start -->

        <div class="preloader" v-if="showloading">
            <div class="preloader-inner">
                <div class="preloader-icon">
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>

        <!-- preloaders end -->
        <!-- header area start -->
        <header>
            <!-- header area start -->
            <div class="header-area">
                <div class="container">
                    <div class="row">
                        <!-- logo start -->
                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                            <div class="header-logo">
                                
                                <router-link to="/"><img src="@/assets/img/logo-p4s.jpeg" alt="logo" class="img-fluid"
                                        >
                                </router-link>
                            </div>
                        </div>
                        <!-- main menu area start -->
                        <div class="col-xl-10 col-lg-10 col-md-9 col-sm-9">
                            <div class="header-top-right float-right">
                                <ul class="header-search" @click="searchshow">
                                    <li><a id="search-btn" href="#"><i class="fa fa-search"></i></a></li>
                                </ul>
                            </div>
                            <div class="main-menu-area text-center">
                                <div class="main-menu">
                                    <nav id="mobile-menu">
                                        <ul>
                                            <!--
                                            
                                            <li><a href="#">blog <i class="fa fa-angle-down"></i></a>
                                                <ul class="sub-menu text-left">
                                                    <li>
                                                        <router-link to="/blog-list">blog list</router-link>
                                                    </li>
                                                    <li>
                                                        <router-link to="/blog-grid">blog grid</router-link>
                                                    </li>
                                                    <li>
                                                        <router-link to="/blog-detail">blog detail</router-link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <router-link to="/contact">Contact</router-link>
                                            </li>
                                        -->
                                            <li v-if="!uData">
                                                <router-link to="/register">ลงทะเบียน</router-link>
                                            </li>
                                            <li v-if="!uData">
                                                <router-link to="/login">login</router-link>
                                            </li>
                                            <li v-if="uData">
                                                <router-link to="/dashboard">Dashboard</router-link>
                                            </li>
                                            <li v-if="uData">
                                                <button class="btn btn-type-2" type="button"
                                                    @click="logout()">Logout</button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <!-- mobile trigger start -->
                            <div class="mobile-menu d-xl-none d-lg-none float-right" @click="mobilemenushow">
                                <div class="toggle-btn"><i class="fa fa-bars"></i></div>
                            </div>
                            <!-- mobile trigger end -->
                        </div>
                        <!-- mobile menu with canvus start -->
                        <div class="offcanvas offcanvas-start" id="offcanvasExample">
                            <!-- Button trigger modal -->
                            <div class="merox-offcanvas-mobile-menu-area position-relative">
                                <button @click="mobilemenuclose" type="button" class="offcanvas-btn-close"><i
                                        class="fa fa-times"></i></button>
                                <div class="merox-canvas-logo">
                                    <router-link to="/">
                                        <img src="@/assets/img/logo-p4s.jpeg" alt="img" />
                                    </router-link>
                                </div>
                                <nav>
                                    <ul>
                                        <!--
                                        
                            <li><a href="#">blog <i class="fa fa-angle-down"></i></a>
                                <ul class="sub-menu text-left">
                                    <li>
                                        <router-link to="/blog-list">blog list</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/blog-grid">blog grid</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/blog-detail">blog detail</router-link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <router-link to="/contact">Contact</router-link>
                            </li>
                        -->
                                        <li v-if="!uData">
                                            <router-link to="/register">ลงทะเบียน</router-link>
                                        </li>
                                        <li v-if="!uData">
                                            <router-link to="/login">login</router-link>
                                        </li>
                                        <li v-if="uData">
                                            <router-link to="/dashboard">Dashboard</router-link>
                                        </li>
                                        <li v-if="uData"> <button class="btn btn-type-2" type="button"
                                                @click="logout()">Logout</button></li>


                                    </ul>
                                </nav>

                                <div class="offcanvas-social">
                                    <ul class="text-center">
                                        <li><a href="#"><i class="fa fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <!-- mobile menu with canvus end -->
                        <!-- main menu area end -->
                    </div>
                </div>
            </div>
            <!-- search form start -->
            <div class="search-form-area" id="search-overlay">
                <div class="search-form-centered">
                    <div id="search-box">

                        <i @click="searchclose" id="close-btn" class="fa fa-times fa-2x"></i>

                        <div class="search-form">
                            <input class="form-control" placeholder="Type your text" type="text">
                            <button>
                                <span>Search</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <!-- search form end -->
        </header>
        <!-- header area end -->
    </div>
</template>

<script>


export default {
    name: "HeaderOne",
    data() {
        return {
            showloading: true,
            uData: null,
        }
    },
    mounted() {
        document.addEventListener("scroll", this.stickMenu);
        //console.log(this.$cookies.get('userData'));
        //console.log(this.$cookies.get('userData'));
        if (this.$cookies.get('userData')) {
            this.uData = this.$cookies.get('userData');
        }

    },
    created() {
        this.preLoading();
        document.addEventListener('scroll', this.topToBottom)
    },
    methods: {
        preLoading() {
            setTimeout(() => {
                this.showloading = false
            }, 1000);
        },
        mobilemenushow() {
            const result = document.querySelector(".offcanvas-start");
            result.classList.add("show");
        },
        mobilemenuclose() {
            const result = document.querySelector(".offcanvas-start");
            result.classList.remove("show");
        },
        searchshow() {
            const result = document.querySelector(".search-form-area");
            result.style.display = "block";
        },
        searchclose() {
            const result = document.querySelector(".search-form-area");
            result.style.display = "none";
        },

        toggleSidebar() {
            this.sidebar = !this.sidebar
        },
        stickMenu() {
            const result = document.querySelector(".header-area");
            if (
                document.body.scrollTop > 100 ||
                document.documentElement.scrollTop > 100
            ) {
                result.classList.add("sticky");
            } else {
                result.classList.remove("sticky");
            }
        },

        logout() {
            this.$cookies.remove('userData');
            console.log('Logout สำเร็จ');
            location.reload();
        }

    },
};
</script>

<style></style>