<template>
    <div>
        <HeaderOne />
        <!-- main area start -->
        <main>
            <!-- breadcrumb area start -->
            <Breadcrumb title="contact" />
            <!-- breadcrumb area end -->
        <!-- merox contact area start -->
        <div id="merox-contact-area" class="merox-contact-area mt-100 mb-100">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="section-title">
                            <h4>{{title}}</h4>
                            <h3>{{title2}}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 pr-30">
                        <div class="appnox-contact-form-area">
                            <div class="appnox-contact-form" id="ajax-contact">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <input class="form-control" type="text" name="name" placeholder="Name *">
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <input class="form-control" type="email" name="email" placeholder="E-mail *">
                                        </div>

                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <input class="form-control" type="text" name="subject" placeholder="Subject">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <textarea class="form-control" name="message" cols="30" rows="8" placeholder="Message"></textarea>
                                            <button class="btn btn-type-1">{{btn_title}}</button>
                                        </div>
                                    </div>
                                    <p class="form-message float-left mt-15"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div class="contact-map-area">
                            <GmapMap :center="{ lat: 40.67, lng: -73.94 }" :zoom="11" map-type-id="terrain"
                            id="merox-map">
                            <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position"
                                :clickable="true" :draggable="true" @click="center = m.position" />
                        </GmapMap>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- merox contact area end -->
        <!-- merox contact info area start -->
        <div id="merox-contact-info-area" class="merox-contact-info-area mb-70">
            <div class="container">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-for="item in items"
                                :key="item.id">
                        <div class="contact-info-box">
                            <div class="contact-info-icon">
                                <img :src="item.img" alt="">
                            </div>
                            <ul class="contact-info" v-html="item.info">
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <!-- merox contact info area end -->
            <FooterOne />
        </main>
        <!-- main area end -->

        <!-- scrolltop button -->
        <a class="material-scrolltop" href="#"></a>
    </div>
</template>

<script>
import Breadcrumb from "../Breadcrumb.vue";
import HeaderOne from "../Header.vue";
import FooterOne from "../Footer.vue";
//contact image
import img1 from "@/assets/img/contact/icon-1.png";
import img2 from "@/assets/img/contact/icon-2.png";
import img3 from "@/assets/img/contact/icon-3.png";
export default {
    name: "TeamPage",
    components: { HeaderOne, Breadcrumb, FooterOne },
    data(){return{
            title:"contact us",
            title2:"contact us",
            btn_title:"Send Message",
            items:[
                {
                    img: img1,
                    info: "<li>+1 (234) 567-8991</li> <li>+1 (234) 567-8991</li>",
                },
                {
                    img: img2,
                    info: "<li>189 Matex Street, Badly Avenue</li> <li>New York, NY-12548</li>",
                },
                {
                    img: img3,
                    info: '<li><a href="mailto:merox@website.com">merox@website.com</a></li> <li><a href="mailto:sales@website.com">sales@website.com</a></li>',
                },]
    }},
    mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods: {
        topToBottom() {
            const result = document.querySelector('.material-scrolltop')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                result.classList.add("reveal");
            } else {
                result.classList.remove("reveal");
            }
        },
    },
};
</script>

<style>

</style>