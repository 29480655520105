<template>
    <div class="breadcrumb-area" >
        <div class="container breadcrumb-area">
            <div class="admin-banner text-center" style="padding-top:150px;">
                <img src="@/assets/img/bg-p4s.jpeg" width="90%"/>
            </div>
            <div class="row first-content">
                <div class="col-md-12">
                    <div class="breadcrumb-content-box">
                        <!--
                        <h3 class="text-black">{{ title }}</h3>
                        -->
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active text-white">{{ title }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BreadcrumbOne',
    props: {
        title: {
            type: String,
        },
    },
}
</script>

<style>


</style>