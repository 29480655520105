var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('HeaderOne'),_c('main',[_c('Breadcrumb',{attrs:{"title":"blog list"}}),_c('div',{staticClass:"merox-blog-area mt-100 mb-100",attrs:{"id":"merox-blog-area"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-8 pr-30"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"single-blog-wraper mb-30 wow fadeInUp"},[_c('div',{staticClass:"blog-img"},[_c('img',{attrs:{"src":_vm.img,"alt":"blog-img"}})]),_c('div',{staticClass:"single-blog-content"},[_c('div',{staticClass:"blog-date"},[_vm._v(_vm._s(_vm.date))]),_c('h4',[_c('a',{staticClass:"post-title",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.title))])]),_c('p',[_vm._v(_vm._s(_vm.para))]),_c('div',{staticClass:"post-info"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.author))]),_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.comment))])])])])]),_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"single-blog-wraper mb-30 wow fadeInUp"},[_c('div',{staticClass:"blog-img-slider"},[_c('carousel',{attrs:{"autoplay":true,"items":1,"nav":false,"loop":true,"dots":true,"smartSpeed":1000,"animateIn":"zoomInDown","responsive":{
                                            0: {
                                                items: 1
                                            },
                                            600: {
                                                items: 1
                                            },
                                            1000: {
                                                items: 1
                                            }
                                        },"responsive-base-element":"body"}},[_c('img',{attrs:{"src":require("@/assets/img/blog/3.jpg"),"alt":"blog-img"}}),_c('img',{attrs:{"src":require("@/assets/img/blog/2.jpg"),"alt":"blog-img"}}),_c('img',{attrs:{"src":require("@/assets/img/blog/1.jpg"),"alt":"blog-img"}})])],1),_c('div',{staticClass:"single-blog-content"},[_c('div',{staticClass:"blog-date"},[_vm._v(_vm._s(_vm.date2))]),_c('h4',[_c('a',{staticClass:"post-title",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.title2))])]),_c('p',[_vm._v(_vm._s(_vm.para2))]),_c('div',{staticClass:"post-info"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.author2))]),_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.comment2))])])])])]),_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"single-blog-wraper mb-30 wow fadeInUp"},[_c('CoolLightBox',{attrs:{"items":_vm.items,"index":_vm.index},on:{"close":function($event){_vm.index = null}}}),_c('div',{staticClass:"blog-video-img text-center position-relative"},[_c('img',{attrs:{"src":_vm.img3,"alt":"blog-img"}}),_c('div',{staticClass:"blog-video-icon"},_vm._l((_vm.items),function(image,imageIndex){return _c('div',{key:imageIndex,staticClass:"blog-popup-video",on:{"click":function($event){_vm.index = imageIndex}}},[_c('i',{staticClass:"fa fa-play"})])}),0)]),_c('div',{staticClass:"single-blog-content"},[_c('div',{staticClass:"blog-date"},[_vm._v(_vm._s(_vm.date3))]),_c('h4',[_c('a',{staticClass:"post-title",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.title3))])]),_c('p',[_vm._v(_vm._s(_vm.para3))]),_c('div',{staticClass:"post-info"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.author3))]),_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.comment3))])])])],1)]),_vm._m(0)]),_c('Sidebar')],1)])]),_c('FooterOne')],1),_c('a',{staticClass:"material-scrolltop",attrs:{"href":"#"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"merox-pagination mt-50"},[_c('nav',{staticClass:"pagination"},[_c('div',{staticClass:"nav-links"},[_c('span',{staticClass:"page-numbers current",attrs:{"aria-current":"page"}},[_vm._v("1")]),_c('a',{staticClass:"page-numbers",attrs:{"href":"#"}},[_vm._v("2")]),_c('a',{staticClass:"next page-numbers",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-long-arrow-right"})])])])])])
}]

export { render, staticRenderFns }