var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"merox-testimonial-area mt-100 mb-100 wow fadeInUp",attrs:{"id":"merox-testimonial-area"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"section-title"},[_c('h4',[_vm._v(_vm._s(_vm.title))]),_c('h3',[_vm._v(_vm._s(_vm.title2))])])])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"testimonial-wraper"},[_c('carousel',{attrs:{"loop":true,"autoplay":true,"smartSpeed":1000,"items":2,"nav":false,"dots":true,"responsive":{
                            0: {
                                items: 1
                            },
                            600: {
                                items: 1
                            },
                            992: {
                                items: 2
                            },
                            1200: {
                                items: 2
                            }
                        },"responsive-base-element":"body"}},[_c('div',{staticClass:"single-testimonial"},[_c('div',{staticClass:"testimonial-img"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/testimonial/client-1.png"),"alt":"testimonial-img"}})]),_c('div',{staticClass:"testimonial-content"},[_c('p',[_vm._v("dummy text of the printing and type setting and industry. Lorem Ipsum has been the industry's")]),_c('div',{staticClass:"title-desig"},[_c('h3',[_vm._v("daniel wells")]),_c('h4',[_vm._v("developer")])])])]),_c('div',{staticClass:"single-testimonial"},[_c('div',{staticClass:"testimonial-img"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/testimonial/client-2.png"),"alt":"testimonial-img"}})]),_c('div',{staticClass:"testimonial-content"},[_c('p',[_vm._v("dummy text of the printing and type setting and industry. Lorem Ipsum has been the industry's")]),_c('div',{staticClass:"title-desig"},[_c('h3',[_vm._v("morgan liu")]),_c('h4',[_vm._v("expert")])])])]),_c('div',{staticClass:"single-testimonial"},[_c('div',{staticClass:"testimonial-img"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/testimonial/client-2.png"),"alt":"testimonial-img"}})]),_c('div',{staticClass:"testimonial-content"},[_c('p',[_vm._v("dummy text of the printing and type setting and industry. Lorem Ipsum has been the industry's")]),_c('div',{staticClass:"title-desig"},[_c('h3',[_vm._v("morgan liu")]),_c('h4',[_vm._v("expert")])])])])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }