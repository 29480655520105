<template>
    <!-- merox testimonial area start -->
    <div id="merox-testimonial-area" class="merox-testimonial-area mt-100 mb-100 wow fadeInUp">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="section-title">
                        <h4>{{ title }}</h4>
                        <h3>{{ title2 }}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="testimonial-wraper">
                        <carousel :loop="true" :autoplay="true" :smartSpeed="1000" :items="2" :nav="false" :dots="true"
                            :responsive="{
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 1
                                },
                                992: {
                                    items: 2
                                },
                                1200: {
                                    items: 2
                                }
                            }" responsive-base-element="body">
                            <div class="single-testimonial">
                                <div class="testimonial-img">
                                    <img src="@/assets/img/testimonial/client-1.png" class="img-fluid"
                                        alt="testimonial-img">
                                </div>
                                <div class="testimonial-content">
                                    <p>dummy text of the printing and type setting and industry. Lorem Ipsum has
                                        been the industry's</p>

                                    <div class="title-desig">
                                        <h3>daniel wells</h3>
                                        <h4>developer</h4>

                                    </div>

                                </div>
                            </div>

                            <div class="single-testimonial">
                                <div class="testimonial-img">
                                    <img src="@/assets/img/testimonial/client-2.png" class="img-fluid"
                                        alt="testimonial-img">
                                </div>
                                <div class="testimonial-content">
                                    <p>dummy text of the printing and type setting and industry. Lorem Ipsum has
                                        been the industry's</p>

                                    <div class="title-desig">
                                        <h3>morgan liu</h3>
                                        <h4>expert</h4>

                                    </div>

                                </div>
                            </div>

                            <div class="single-testimonial">
                                <div class="testimonial-img">
                                    <img src="@/assets/img/testimonial/client-2.png" class="img-fluid"
                                        alt="testimonial-img">
                                </div>
                                <div class="testimonial-content">
                                    <p>dummy text of the printing and type setting and industry. Lorem Ipsum has
                                        been the industry's</p>

                                    <div class="title-desig">
                                        <h3>morgan liu</h3>
                                        <h4>expert</h4>

                                    </div>

                                </div>
                            </div>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- merox testimonial area end -->
</template>

<script>
import carousel from 'vue-owl-carousel2'
export default {
    name: 'TestimonialOne',
    components: { carousel },
    data() {
        return {
            title: "TESTIMONIALS",
            title2: "CLIENT’S SAY",
        }
    }
}
</script>

<style>

</style>