<template>
    <!-- merox footer area start -->
    <footer>
        <div id="merox-footer-area" class="merox-footer-area pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        <div class="footer-single-wedget">
                            <div class="custom-html-widget">
                                <div class="footer-logo">
                                    <a href="#"><img :src="logo" alt="footer-logo" class="img-fluid"></a>
                                </div>
                                <p>{{ para }}</p>
                                <ul class="footer-social" v-html="social">
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        <div class="footer-single-wedget">
                            <div class="widget_nav_menu">
                                <h3>{{ q_title }}</h3>
                                <ul class="menu" v-html="quick_list">
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        <div class="footer-single-wedget">
                            <div class="widget_recent_entries">
                                <h3>{{ rp_title }}</h3>
                                <ul v-html="rp_list">
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        <div class="footer-single-wedget">
                            <div class="custom-html-widget">
                                <h3>{{ ci_title }}</h3>
                                <ul class="contact-details" v-html="ci_list"></ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- merox footer copyright area start -->
        <div class="merox-copyright-area pt-30 pb-30">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12 col-lg-12">
                        <div class="footer-copyright text-center">
                            <p v-html="copy_right"></p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- merox footer copyright area end -->
    </footer>
    <!-- merox footer area end -->
</template>

<script>
import img from '@/assets/img/logo-1.png'
export default {
    name: 'FooterOne',
    data() {
        return {
            logo: img,
            para: "There are many variations of but the majority have suffered inject’s humour, or randomised word and don't look even slightly believable. If you are going to use",
            social: '<li><a href="#"><i class="fa fa-facebook"></i></a></li><li><a href="#"><i class="fa fa-twitter"></i></a></li><li><a href="#"><i class="fa fa-instagram"></i></a></li><li><a href="#"><i class="fa fa-vk"></i></a></li>',
            q_title: "Quick Link",
            quick_list: '<li><a href="#">help center </a></li><li><a href="#">Privacy Policy</a></li><li><a href="#">Support Policy</a></li><li><a href="#">Terms & Conditions</a></li><li><a href="#">about us</a></li><li><a href="#">contact</a></li>',
            rp_title: "Recent Post",
            rp_list: '<li><a href="#">How to build a company culture</a><span>Sep 30, 2019</span></li><li><a href="#">How to build a company culture</a><span>Sep 30, 2019</span></li><li><a href="#">How to build a company culture</a><span>Sep 30, 2019</span></li>',
            ci_title: "Contact Info",
            ci_list: '<li><i class="fa fa-map-marker"></i> address: 679 grand avenu, central parl, nyc, ny</li><li><i class="fa fa-headphones"></i> custom support & sale: +012-345-678</li><li><i class="fa fa-clock-o"></i> working time: mon-sat: 9 am – 5 pm</li>',
            copy_right: '© All Right Reserved by <a href="#">theme_group</a>',

        }
    }
}
</script>

<style>

</style>