<template>
    <!-- merox subscribe area start -->
    <div id="merox-subscribe-area" class="merox-subscribe-area pt-100 pb-100">

        <div class="container position-relative">

            <div class="admin-banner mt-5">

                <img src="@/assets/img/bg-p4s.jpeg" width="100%" />
            </div>
            <div class="row sub-content" v-if="stationData">
                <div class="col-xl-5 col-lg-5 wow fadeInLeft" v-if="uData">
                    <div class="subscribe-title">
                        <h4 class="section-title text-white">พื้นที่ใช้งานสำหรับ<br /><span v-html="title"></span></h4>
                        <h5 class="text-white">ผู้ใช้ปัจจุบัน: <span v-html="uData.user_fname"></span></h5>

                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 offset-xl-1  wow fadeInRight">

                    <div class="subscribe-form-box">
                        <div>
                            <h2>สถานะของเครื่อง</h2>
                            <div style="display: flex;">
                                <div style="flex: 0.3; padding-top: 20px;" class="text-right"><h1 class="text-blue">ปิด</h1></div>
                                <div style="flex: 0.4; padding: 10px;" class="text-center">
                                    <InputSwitch v-model="checked" @click="sendData" />
                                </div>
                                <div style="flex: 0.3; padding-top: 20px;" class="text-left" ><h1 class="text-red">เปิด</h1></div>
                            </div>

                            <div class="text-black">อัพเดทล่าสุด: {{ stationData.newState }} เมื่อ {{
                stationData.lastUpdated }}
                            </div>
                            <!--<p>{{ checked ? 'เปิดอยู่' : 'ปิดอยู่' }}</p>-->
                        </div>

                    </div>
                </div>
            </div>
            <!-- preloaders start -->
            <div class="preloader" v-else>
                <div class="preloader-inner">
                    <div class="preloader-icon">
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- merox subscribe area end -->
</template>

<script>
import { secretCreator } from '@/api/secret';
import InputSwitch from 'primevue/inputswitch';
export default {
    name: 'SubscribeOne',
    components: {
        InputSwitch
    },
    data() {
        return {
            uData: null,
            title: "พื้นที่ใช้งานสำหรับโรงเรียน<br>",
            btn_title: 'OFF',
            btnClass: '',
            stationData: null,
            secretToken: secretCreator(),
            checked: false
        }
    },
    created() {
        //console.log(secretCreator());
        setInterval(() => {
            this.fetchData();

        }, 10000)
    },
    mounted() {

        this.fetchData();

    },
    methods: {
        fetchData() {
            if (this.$cookies.get('userData')) {
                //console.log(555);
                this.uData = this.$cookies.get('userData');
                this.title = this.uData.station_name;

                let formData = new FormData();
                formData.append('station_token', this.uData.user_token);
                formData.append('secret_token', this.secretToken);
                formData.append('user_id', this.uData.user_id);
                //console.log(this.uData.user_token);

                this.axios
                    .post(this.$hostUrl + "php_action/updateState.php", formData)
                    .then((response) => {
                        //console.log(response.data);
                        this.stationData = response.data;
                        if (response.data.newState == 'Off') {
                            this.btn_title = 'กดเพื่อ  เปิด';
                            this.btnClass = 'btn-stand-by';
                            this.checked = false;
                        }
                        else {
                            this.btn_title = 'กดเพื่อ  ปิด';
                            this.btnClass = 'btn-alarming';
                            this.checked = true;
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        sendData() {
            let formData = new FormData();
            formData.append('station_token', this.uData.user_token);
            formData.append('trigger', '1');
            formData.append('secret_token', this.secretToken);
            formData.append('user_id', this.uData.user_id);
            this.axios
                .post(this.$hostUrl + "php_action/updateState.php", formData)
                .then((response) => {
                    console.log(response.data);
                    this.stationData = response.data;
                    if (response.data.newState == 'Off') {
                        this.btn_title = 'กดเพื่อ เปิด';
                        this.btnClass = 'btn-stand-by';
                        this.checked = false;
                    }
                    else {
                        this.btn_title = 'กดเพื่อ ปิด';
                        this.btnClass = 'btn-alarming';
                        this.checked = true;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
}
</script>

<style></style>