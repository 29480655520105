<template>
    <div>
        <HeaderOne />
        <!-- main area start -->
        <main>
            <!-- breadcrumb area start -->
            <Breadcrumb title="error" />
            <!-- breadcrumb area end -->
        <!-- merox error area start -->
        <div id="merox-error-area" class="merox-error-area text-center mt-100 mb-100">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 mx-auto">
                        <div class="merox-content-box">
                            <h2>{{title}}</h2>
                            <h3>{{title2}}</h3>
                            <p>{{title3}}</p>
                            <div class="error-form-box">
                                <input class="form-control" placeholder="search" name="text" type="text">
                                <button class="btn btn-type-1">{{title4}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- merox error area end -->
            <FooterOne />
        </main>
        <!-- main area end -->
        <!-- scrolltop button -->
        <a class="material-scrolltop" href="#"></a>
    </div>
</template>

<script>
import Breadcrumb from "../Breadcrumb.vue";
import HeaderOne from "../Header.vue";
import FooterOne from "../Footer.vue";
export default {
    name: "TeamPage",
    components: { HeaderOne, Breadcrumb, FooterOne },
    data(){return{
    title:"404",
    title2:"Oops! That Page Can Not Be Found.",
    title3:"it looks like nothing was found at this location. maybe try a search?",
    title4:"submit",
    }},
    mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods: {
        topToBottom() {
            const result = document.querySelector('.material-scrolltop')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                result.classList.add("reveal");
            } else {
                result.classList.remove("reveal");
            }
        },
    },
};
</script>

<style>

</style>