import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import * as VueGoogleMaps from 'vue2-google-maps'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/css/fontawesome-all.min.css'
import '../src/assets/css/default.css'
import '../src/assets/css/scrolltop.css'
import '../src/assets/css/wedget.css'
import '../src/assets/css/style.css'
import '../src/assets/css/responsive.css'

import axios from 'axios'
import VueAxios from 'vue-axios'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueCookies from 'vue-cookies';

import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css';       //theme
import 'primevue/resources/primevue.min.css';                //core css
import 'primeicons/primeicons.css';                          //icons







Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.use(PrimeVue);
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCJRG4KqGVNvAPY4UcVDLcLNXMXk2ktNfY',
        libraries: 'places',
    },
})
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueAxios, axios)

Vue.use(VueCookies, { expires: '7d' })

Vue.config.productionTip = false

Vue.prototype.$hostUrl = 'https://p-4s.com/';

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')