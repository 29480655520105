<template>
    <div>
        <HeaderOne />
        <!-- main area start -->
        <main>
            <!-- breadcrumb area start -->
            <Breadcrumb title="blog list" />
            <!-- breadcrumb area end -->
            <!-- merox blog area start -->
            <div id="merox-blog-area" class="merox-blog-area mt-100 mb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 pr-30">
                            <!-- single blog post -->
                            <div class="col-xl-12">
                                <div class="single-blog-wraper mb-30  wow fadeInUp">
                                    <div class="blog-img"><img :src="img" alt="blog-img" /></div>
                                    <div class="single-blog-content">
                                        <div class="blog-date">{{ date }}</div>
                                        <h4><a href="#" class="post-title">{{ title }}</a></h4>
                                        <p>{{ para }}</p>
                                        <div class="post-info">
                                            <a href="#">{{ author }}</a>
                                            <a href="#">{{ comment }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- single blog post -->
                            <div class="col-xl-12">
                                <div class="single-blog-wraper mb-30  wow fadeInUp">
                                    <div class="blog-img-slider">
                                        <carousel :autoplay="true" :items="1" :nav="false" :loop="true" :dots="true"
                                            :smartSpeed="1000" animateIn="zoomInDown" :responsive="{
                                                0: {
                                                    items: 1
                                                },
                                                600: {
                                                    items: 1
                                                },
                                                1000: {
                                                    items: 1
                                                }
                                            }" responsive-base-element="body">
                                            <img src="@/assets/img/blog/3.jpg" alt="blog-img" />
                                            <img src="@/assets/img/blog/2.jpg" alt="blog-img" />
                                            <img src="@/assets/img/blog/1.jpg" alt="blog-img" />
                                        </carousel>
                                    </div>
                                    <div class="single-blog-content">
                                        <div class="blog-date">{{ date2 }}</div>
                                        <h4><a href="#" class="post-title">{{ title2 }}</a></h4>
                                        <p>{{ para2 }}</p>
                                        <div class="post-info">
                                            <a href="#">{{ author2 }}</a>
                                            <a href="#">{{ comment2 }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- single blog post -->
                            <div class="col-xl-12">
                                <div class="single-blog-wraper mb-30  wow fadeInUp">
                                    <CoolLightBox :items="items" :index="index" @close="index = null">
                                    </CoolLightBox>
                                    <div class="blog-video-img text-center position-relative">
                                        <img :src="img3" alt="blog-img" />
                                        <div class="blog-video-icon">
                                            <div class="blog-popup-video" v-for="(image, imageIndex) in items"
                                                :key="imageIndex" @click="index = imageIndex"><i class="fa fa-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single-blog-content">
                                        <div class="blog-date">{{ date3 }}</div>
                                        <h4><a href="#" class="post-title">{{ title3 }}</a></h4>
                                        <p>{{ para3 }}</p>
                                        <div class="post-info">
                                            <a href="#">{{ author3 }}</a>
                                            <a href="#">{{ comment3 }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-12">
                                <div class="merox-pagination mt-50">
                                    <nav class="pagination">
                                        <div class="nav-links"><span aria-current="page"
                                                class="page-numbers current">1</span>
                                            <a class="page-numbers" href="#">2</a>
                                            <a class="next page-numbers" href="#"><i
                                                    class="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <Sidebar />
                    </div>
                </div>
            </div>
            <!-- merox blog area end -->
            <FooterOne />
        </main>
        <!-- main area end -->
        <!-- scrolltop button -->
        <a class="material-scrolltop" href="#"></a>
    </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import carousel from 'vue-owl-carousel2'
import img1 from '@/assets/img/blog/1.jpg'
import img3 from '@/assets/img/blog/2.jpg'
import Breadcrumb from "../Breadcrumb.vue";
import Sidebar from "../Sidebar.vue";
import HeaderOne from "../Header.vue";
import FooterOne from "../Footer.vue";
export default {
    name: "TeamPage",
    components: { HeaderOne, Sidebar, Breadcrumb, FooterOne, carousel, CoolLightBox },
    data() {
        return {
            img: img1,
            date: "14 NOV 2019",
            title: "in spring dumont opens mixed reality experience",
            para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered teration some form, by injected humou",
            author: "by: admin",
            comment: "0 comments",
            date2: "14 NOV 2019",
            title2: "in spring dumont opens mixed reality experience",
            para2: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered teration some form, by injected humou",
            author2: "by: admin",
            comment2: "0 comments",
            img3: img3,
            date3: "14 NOV 2019",
            title3: "in spring dumont opens mixed reality experience",
            para3: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered teration some form, by injected humou",
            author3: "by: admin",
            comment3: "0 comments",
            items: [
                {
                    src: 'https://www.youtube.com/watch?v=snvzakfcTmY',
                },
            ],
            index: null
        }
    },
    mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods: {
        topToBottom() {
            const result = document.querySelector('.material-scrolltop')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                result.classList.add("reveal");
            } else {
                result.classList.remove("reveal");
            }
        },
    },
};
</script>

<style>

</style>