<template>
    <div>
        <HeaderOne />
        <!-- main area start -->
        <main>
            <!-- breadcrumb area start -->
            <Breadcrumb title="about" />
            <!-- breadcrumb area end -->
            <!-- merox about area start -->
            <div id="merox-about-area" class="merox-about-area mt-100 mb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-5 col-lg-6 col-md-12 wow fadeInLeft">
                            <div class="about-img-area"><img src="@/assets/img/about/about-1.jpg" alt="about" /></div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 offset-xl-1 wow fadeInRight">
                            <div class="section-title">
                                <h4>{{ title }}</h4>
                                <h3 v-html="title2"></h3>
                            </div>
                            <div class="about-content">
                                <p>{{ paragraph }}</p>
                                <ul class="about-content-list" v-html="list"></ul>
                                <p>{{ paragraph2 }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- merox about area end -->
            <!-- merox counter area start -->
            <CounterOne />
            <!-- merox counter area end -->
            <FooterOne />
        </main>
        <!-- main area end -->

        <!-- scrolltop button -->
        <a class="material-scrolltop" href="#"></a>
    </div>
</template>

<script>
import Breadcrumb from "../Breadcrumb.vue";
import CounterOne from "../Counter.vue";
import HeaderOne from "../Header.vue";
import FooterOne from "../Footer.vue";
export default {
    name: "AboutUs",
    components: { HeaderOne, CounterOne, Breadcrumb, FooterOne },
    mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods: {
        topToBottom() {
            const result = document.querySelector('.material-scrolltop')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                result.classList.add("reveal");
            } else {
                result.classList.remove("reveal");
            }
        },
    },
    data() {
        return {
            title: "MEROX OVERVIEW",
            title2: "about <span>merox</span> pro",
            paragraph: "There are many variations of passages of Lorem Ipsum but the majority have suffered alteration in some form injected, or randomised words which don't look even slightly. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything",
            paragraph2: "There are many variations of passages of Lorem Ipsum but the majority have suffered alteration in some form injected, or randomised words which don't look even slightly.",
            list: '<li><i class="fa fa-check"></i> There are many variations of passages of Lorem</li><li><i class="fa fa-check"></i> Sure there is not anything embarrassing hidden in the</li><li><i class="fa fa-check"></i> All the Lorem Ipsum generators on the Internet tend to</li>',
        }
    },
};
</script>

<style>

</style>