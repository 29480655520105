<template>
    <!-- merox subscribe area start -->
    <div>
        <div class="container position-relative">
            <div class="row " v-if="stationData">
                <div v-if="uData">
                    <div class="subscribe-title" v-if="uData.user_status == 7">
                        <h3 v-html="title"></h3>
                        <h5 class="text-white">ผู้ใช้ปัจจุบัน: {{ uData.user_fname }}</h5>
                    </div>
                </div>
                <div class="col-lg-12" v-if="uData.user_status == 7">
                    <div class="row">
                        <div class="col-lg-12">
                            <h3><strong>กำลังเปิดใช้งาน</strong></h3>
                        </div>
                    </div>

                    <div class="row" v-if="listOn.length > 0">
                        <div class="col-lg-4" v-for="station in listOn" :key="station.id">
                            <div>
                                <div class="alert bg-red">
                                    ชื่อโรงเรียน: {{ station.stationName }} <br />
                                    สถานะ: {{ station.state }}<br />
                                    อัพเดทครั้งล่าสุด: {{ station.timeStamp }}<br />
                                    ออนไลน์ล่าสุด: {{ station.lastPing }}
                                </div>

                            </div>
                        </div>


                    </div>
                    <div class="row" v-else>
                        <div class="col-lg-12">
                            --ไม่พบอุปกรณ์--
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h3><strong>ออนไลน์</strong></h3>
                        </div>
                    </div>
                    <div class="row" v-if="listOff.length > 0">
                        <div class="col-lg-4" v-for="station in listOff" :key="station.id">
                            <div>
                                <div class="alert bg-green">
                                    ชื่อโรงเรียน: {{ station.stationName }} <br />
                                    สถานะ: {{ station.state }}<br />
                                    อัพเดทครั้งล่าสุด: {{ station.timeStamp }}<br />
                                    ออนไลน์ล่าสุด: {{ station.lastPing }}
                                </div>

                            </div>
                        </div>


                    </div>
                    <div class="row" v-else>
                        <div class="col-lg-12">
                            --ไม่พบอุปกรณ์--
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h3><strong>ออฟไลน์</strong></h3>
                        </div>
                    </div>
                    <div class="row" v-if="listOffline">
                        <div class="col-lg-4" v-for="station in listOffline" :key="station.id">
                            <div>
                                <div class="alert alert-dark">
                                    ชื่อโรงเรียน: {{ station.stationName }} <br />
                                    สถานะ: {{ station.state }}<br />
                                    อัพเดทครั้งล่าสุด: {{ station.timeStamp }}<br />
                                    ออนไลน์ล่าสุด: {{ station.lastPing }}
                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>
            <!-- preloaders start -->
            <div v-else>
                <div class="preloader-inner">
                    <div class="preloader-icon">
                        Loading...
                    </div>
                </div>
            </div>


        </div>
    </div>
    <!-- merox subscribe area end -->
</template>

<script>
import { secretCreator } from '@/api/secret';
export default {
    name: 'adminDashboard',
    data() {
        return {
            uData: null,
            title: "พื้นที่ใช้งานสำหรับ Admin<br>",
            btn_title: 'OFF',
            btnClass: 'btn btn-type-2',
            stationData: null,
            listOffline: [],
            listOff: [],
            listOn: [],
            secretToken: secretCreator()
        }
    },
    created() {
        setInterval(() => {
            this.ping()

        }, 20000)
    },
    mounted() {
        
        this.ping();
    },
    methods: {

        ping() {

            if (this.$cookies.get('userData')) {
                this.uData = this.$cookies.get('userData');
            }
            else {
                this.$router.push({ name: 'Login' });
            }
            if (this.$cookies.get('userData')) {
                this.uData = this.$cookies.get('userData');
                let formData = new FormData();
                formData.append('txtAction', 'getStations');
                formData.append('secret_token', this.secretToken);

                this.axios
                    .post(this.$hostUrl + "php_action/stationAPI.php", formData)
                    .then((response) => {
                        //console.log(response.data);
                        this.stationData = response.data;

                        this.listOffline = [];
                        this.listOff = [];
                        this.listOn = [];
                        (response.data.mainData).forEach(element => {
                            //let nowDt = new Date();
                            //let pingDt = new Date(element.lastPing);
                            //console.log(element.lastPing);
                            //console.log(nowDt);
                            //console.log(pingDt);
                            //console.log(nowDt - pingDt);
                            if (element.lastPing) {
                                if (new Date() - new Date(element.lastPing) > 100000000) {
                                    this.listOffline.push(element);
                                }
                                else if (element.state == 'On') {
                                    this.listOn.push(element);
                                }
                                else if (element.state == 'Off') {
                                    this.listOff.push(element);
                                }
                            }
                            else {
                                this.listOffline.push(element);
                            }


                        });

                        //console.log(this.listOffline);
                        console.log(this.listOn);
                        console.log(this.listOff);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    }
}
</script>

<style></style>