<template>
    <div>
        <HeaderOne />
        <!-- main area start -->
        <main>
            <SubscribeOne />
           
            <AdminDashboard />
        <!--
            <ClientOne />
        
            <FooterOne />
            -->
        </main>
        <!-- main area end -->
        <!-- scrolltop button -->
        <a class="material-scrolltop" href="#"></a>
    </div>
</template>

<script>
import HeaderOne from '../Header.vue'
//import HeroOne from '../Hero.vue'
//import AboutOne from '../About.vue'
//import CounterOne from '../Counter.vue'
//import ServiceOne from '../Service.vue'
//import TeamOne from '../Team.vue'
//import CtaOne from '../Cta.vue'
//import TestimonialOne from '../Testimonial.vue'
//import VideoOne from '../Video.vue'
//import BlogOne from '../Blog.vue'
import SubscribeOne from '../Subscribe.vue'
//import ClientOne from '../Client.vue'
//import FooterOne from '../Footer.vue'
import AdminDashboard from '../AdminDashboard.vue'
export default {
    name: 'HomeOne',
    components: { HeaderOne, SubscribeOne,  AdminDashboard},
    deta() {
        return {
            uData: null
        };
    },

    mounted() {

        this.uData = this.$cookies.get('userData');
        //console.log(this.uData);
        document.addEventListener('scroll', this.topToBottom)
    },
    methods: {
        topToBottom() {
            const result = document.querySelector('.material-scrolltop')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                result.classList.add("reveal");
            } else {
                result.classList.remove("reveal");
            }
        },
    }

}
</script>

<style></style>