<template>
    <div>
        <HeaderOne />
        <!-- main area start -->
        <main>
            <!-- breadcrumb area start -->
            <Breadcrumb title="ลงทะเบียนผู้ใช้งาน" />
            <!-- breadcrumb area end -->
            <!-- merox contact area start -->
            <div id="merox-contact-area" class="merox-contact-area mt-100 mb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="section-title">
                                <h3>{{ title }}</h3>
                                <h4>{{ title2 }}</h4>
                                <font-awesome-icon icon="fa-solid fa-user-secret" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <form id="formCreateUser" v-on:submit.prevent="createUser" method="POST">
                        <div class="row">

                            <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 pr-30">
                                <div class="appnox-contact-form-area">
                                    <div class="appnox-contact-form" id="ajax-contact">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <input class="form-control" type="text" name="userName"
                                                        placeholder="Username *" required>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <input class="form-control" type="text" name="userPw"
                                                        placeholder="Password *" required>
                                                </div>

                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <input class="form-control" type="text" name="fName"
                                                        placeholder="ชื่อ นามสกุล *" required>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <textarea class="form-control" name="token" cols="30" rows="4"
                                                        placeholder="Device Token *" required></textarea>
                                                    <button type="submit" class="btn btn-type-1">{{ btn_title }}</button>
                                                </div>
                                            </div>
                                            <p class="form-message float-left mt-15"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--
                                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                                    <div class="contact-map-area">
                                        <GmapMap :center="{ lat: 40.67, lng: -73.94 }" :zoom="11" map-type-id="terrain"
                                            id="merox-map">
                                            <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position"
                                                :clickable="true" :draggable="true" @click="center = m.position" />
                                        </GmapMap>
                                    </div>
                                </div>
                            -->

                        </div>
                    </form>
                </div>
            </div>
            <!-- merox contact area end -->
            <!-- merox contact info area start -->
            <div id="merox-contact-info-area" class="merox-contact-info-area mb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-for="item in items" :key="item.id">
                            <div class="contact-info-box">
                                <div class="contact-info-icon">
                                    <img :src="item.img" alt="">
                                </div>
                                <ul class="contact-info" v-html="item.info">
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- merox contact info area end -->
            <!--    
                                    <FooterOne />
                            -->
        </main>
        <!-- main area end -->

        <!-- scrolltop button -->
        <a class="material-scrolltop" href="#"></a>
    </div>
</template>

<script>
import Breadcrumb from "../Breadcrumb.vue";
import HeaderOne from "../Header.vue";
//import FooterOne from "../Footer.vue";
//contact image
import img1 from "@/assets/img/contact/icon-1.png";
import img2 from "@/assets/img/contact/icon-2.png";
import img3 from "@/assets/img/contact/icon-3.png";
export default {
    name: "TeamPage",
    components: {
        HeaderOne, Breadcrumb
    },
    data() {
        return {
            title: "เพิ่มรายชื่อผู้ใช้งาน",
            title2: "ประจำสถานี",
            btn_title: "Register",
            items: [
                {
                    img: img1,
                    info: "<li>08-6468-9755</li> <li>06-2772-2658</li>",
                },
                {
                    img: img2,
                    info: "<li>Beaming Technology and Supply</li> <li>Sisaket, Thailand.</li>",
                },
                {
                    img: img3,
                    info: '<li><a href="mailto:jettha.dev@gmail.com">jettha.dev@gmail.com</a></li> <li><a href="mailto:jett.bioteacher@gmail.com">jett.bioteacher@gmail.com</a></li>',
                },]
        }
    },
    mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods: {
        topToBottom() {
            const result = document.querySelector('.material-scrolltop')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                result.classList.add("reveal");
            } else {
                result.classList.remove("reveal");
            }
        },
        createUser(event) {
            let formData = new FormData(document.getElementById(event.target.id));
            formData.append('txtAction', 'createUser');
            this.axios
                .post(this.$hostUrl + "php_action/userAPI.php", formData)
                .then((response) => {
                    console.log(response.data);
                    if(response.data.createState == "created"){
                        alert('บันทึกข้อมูลสำเร็จ');
                        location.replace('./login');
                    }
                    else{
                        alert(response.data.error);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
};
</script>

<style></style>