<template>

    <div class="col-xl-4 col-lg-4">
        <!-- search wedget -->
        <div class="single-sid-wdg sm-mt-30">
            <div action="#" class="wdg-search-form">
                <input type="text" placeholder="search ">
                <button class="submit-btn"><i class="fa fa-search"></i></button>
            </div>
        </div>
        <!-- latest post wedget -->
        <div class="single-sid-wdg">
            <div class="widget_recent_entries">
                <h3>popular posts</h3>
                <div class="single-wdg-post">
                    <div class="wdg-post-img">
                        <a href="#">
                            <img src="@/assets/img/wdg/wdg-img-1.jpg" alt="blog thumbnail" class="img-fluid">
                        </a>
                    </div>
                    <div class="wdg-post-content">

                        <h5><a href="#">How to build a company culture of success</a></h5>
                        <span>Sep 30, 2019</span>
                    </div>

                </div>

                <div class="single-wdg-post">
                    <div class="wdg-post-img">
                        <a href="#">
                            <img src="@/assets/img/wdg/wdg-img-2.jpg" alt="blog thumbnail" class="img-fluid">
                        </a>
                    </div>
                    <div class="wdg-post-content">

                        <h5><a href="#">Investment Update, Successful people ask</a></h5>
                        <span>Dec 11, 2019</span>
                    </div>

                </div>

                <div class="single-wdg-post">
                    <div class="wdg-post-img">
                        <a href="#">
                            <img src="@/assets/img/wdg/wdg-img-3.jpg" alt="blog thumbnail" class="img-fluid">
                        </a>
                    </div>
                    <div class="wdg-post-content">

                        <h5><a href="#">Investment Update, Successful people ask</a></h5>
                        <span>Jan 15, 2019</span>
                    </div>

                </div>
            </div>
        </div>

        <!-- Follow wedget -->
        <div class="single-sid-wdg">
            <h3>follow us</h3>
            <ul class="wdg-follow-us">
                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                <li><a href="#"><i class="fa fa-instagram"></i></a></li>
            </ul>
        </div>

        <!-- tags wedget -->
        <div class="single-sid-wdg">
            <div class="widget_tag_cloud">
                <h3>post tags</h3>
                <div class="tagcloud">
                    <a href="#">awesome</a>
                    <a href="#">template</a>
                    <a href="#">ui/ux</a>
                    <a href="#">software</a>
                    <a href="#">develop</a>
                </div>
            </div>
        </div>

        <!-- tags wedget -->
        <div class="single-sid-wdg">
            <div class="widget_categories">
                <h3>categories</h3>
                <ul>
                    <li><a href="#">gallery</a></li>
                    <li><a href="#">business</a></li>
                    <li><a href="#">office</a></li>
                    <li><a href="#">design</a></li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'SidebarOne',
}
</script>

<style>

</style>