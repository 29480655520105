<template>
    <div>
        <HeaderOne />
        <!-- main area start -->
        <main>
            <!-- breadcrumb area start -->
            <Breadcrumb title="testimonial" />
            <!-- breadcrumb area end -->
            <!-- merox testimonial area start -->
            <TestimonialOne />
            <!-- merox testimonial area end -->
            <FooterOne />
        </main>
        <!-- main area end -->
        <!-- scrolltop button -->
        <a class="material-scrolltop" href="#"></a>
    </div>
</template>

<script>
import Breadcrumb from "../Breadcrumb.vue";
import TestimonialOne from "../Testimonial.vue";
import HeaderOne from "../Header.vue";
import FooterOne from "../Footer.vue";
export default {
    name: "TeamPage",
    components: { HeaderOne, TestimonialOne, Breadcrumb, FooterOne },
    mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods: {
        topToBottom() {
            const result = document.querySelector('.material-scrolltop')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                result.classList.add("reveal");
            } else {
                result.classList.remove("reveal");
            }
        },
    },
};
</script>

<style>

</style>