<template>
    <!-- merox team area start -->
    <div id="merox-team-area" class="merox-team-area mt-100 mb-70 wow fadeInUp">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="section-title">
                        <h4>{{ title }}</h4>
                        <h3>{{ title2 }}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12" v-for="item in team_items" :key="item.id">
                    <div class="single-team-wrapper text-center">
                        <div class="team-img">
                            <img :src="item.img" alt="team" />
                        </div>
                        <div class="team-content">
                            <h4>{{ item.title }}</h4>
                            <span>{{ item.desig }}</span>
                            <div class="team-social-box" v-html="item.social">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- merox team area end -->
</template>

<script>
import img1 from "@/assets/img/team/1.jpg";
import img2 from "@/assets/img/team/2.jpg";
import img3 from "@/assets/img/team/3.jpg";
import img4 from "@/assets/img/team/4.jpg";
export default {
    name: 'TeamOne',
    data() {
        return {
            title: "MEROX TEAM",
            title2: "EXPERIENCE TEAM",
            team_items: [
                {
                    img: img1,
                    title: "David Walillams",
                    desig: "CONSULTANT",
                    social: '<a href="#"><i class="fa fa-facebook"></i></a><a href="#"><i class="fa fa-twitter"></i></a><a href="#"><i class="fa fa-instagram"></i></a><a href="#"><i class="fa fa-vk"></i></a>',
                },
                {
                    img: img2,
                    title: "Benjamin Mark",
                    desig: "DIRECTOR",
                    social: '<a href="#"><i class="fa fa-facebook"></i></a><a href="#"><i class="fa fa-twitter"></i></a><a href="#"><i class="fa fa-instagram"></i></a><a href="#"><i class="fa fa-vk"></i></a>',
                },
                {
                    img: img3,
                    title: "Steve Louis",
                    desig: "DEVELOPER",
                    social: '<a href="#"><i class="fa fa-facebook"></i></a><a href="#"><i class="fa fa-twitter"></i></a><a href="#"><i class="fa fa-instagram"></i></a><a href="#"><i class="fa fa-vk"></i></a>',
                },
                {
                    img: img4,
                    title: "Shawn Philip",
                    desig: "DESIGNER",
                    social: '<a href="#"><i class="fa fa-facebook"></i></a><a href="#"><i class="fa fa-twitter"></i></a><a href="#"><i class="fa fa-instagram"></i></a><a href="#"><i class="fa fa-vk"></i></a>',
                },
            ]
        }
    }
}
</script>

<style>

</style>