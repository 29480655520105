import Vue from "vue";
import VueRouter from "vue-router";
//import HomeOne from "../components/Pages/index";
//import zHomeOne from "../components/Pages/zindex";
//import Landing from "../components/Pages/landing";
import About from "../components/Pages/about";
import Team from "../components/Pages/team";
import Testimonial from "../components/Pages/testimonial";
import Error from "../components/Pages/error";
import BlogList from "../components/Pages/bloglist";
import BlogGrid from "../components/Pages/bloggrid";
import BlogDetail from "../components/Pages/blogdetail";
import Contact from "../components/Pages/contact";
import Register from "../components/Pages/register";
import Login from "../components/Pages/login";
import Dashboard from "../components/Pages/dashboard";

Vue.use(VueRouter);

const routes = [{
        path: '/',
        component: Login,
    },
    {
        path: '/about',
        component: About,
    },
    {
        path: '/team',
        component: Team,
    },
    {
        path: '/testimonial',
        component: Testimonial,
    },
    {
        path: '/error',
        component: Error,
    },
    {
        path: '/blog-list',
        component: BlogList,
    },
    {
        path: '/blog-grid',
        component: BlogGrid,
    },
    {
        path: '/blog-detail',
        component: BlogDetail,
    },
    {
        path: '/contact',
        component: Contact,
    },
    {
        path: '/register',
        component: Register,
    },
    {
        path: '/login',
        component: Login,
        name: 'Login'
    },
    {
        path: '/dashboard',
        component: Dashboard,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    // stop stop cache
    scrollBehavior() {
        document.getElementById("app").scrollIntoView();
    },
});

export default router;