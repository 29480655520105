function secretCreator() {
    let a, b, c, d, e;
    let result = 0;

    // Keep generating random numbers until the equation a + b - c * d = 100 is satisfied
    while (result !== 100) {
        a = Math.floor(Math.random() * 101); // Generates a random integer between 0 and 100
        b = Math.floor(Math.random() * 101);
        c = Math.floor(Math.random() * 101);
        d = Math.floor(Math.random() * 101);
        e = Math.floor(Math.random() * 101);

        result = a + b - c * d + e;
    }
    return `${a}-${b}-${c}-${d}-${e}`;
}
export { secretCreator };