<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree&display=swap');

.main-menu nav>ul>li:last-child a {
  font-family: 'Bai Jamjuree', sans-serif;
}

.merox-offcanvas-mobile-menu-area nav>ul>li:last-child a {
  font-family: 'Bai Jamjuree', sans-serif;
}

.breadcrumb-content-box h2 {
  font-family: 'Bai Jamjuree', sans-serif;
}

.breadcrumb-content-box .breadcrumb .breadcrumb-item,
.breadcrumb-content-box .breadcrumb-item.active a,
.breadcrumb-content-box .breadcrumb .breadcrumb-item.active {
  font-family: 'Bai Jamjuree', sans-serif;
}

.section-title h4 {
  font-family: 'Bai Jamjuree', sans-serif;
}

.section-title h3 {
  font-family: 'Bai Jamjuree', sans-serif;
}

.appnox-contact-form input.form-control {
  font-family: 'Bai Jamjuree', sans-serif;
}

.appnox-contact-form textarea.form-control {
  font-family: 'Bai Jamjuree', sans-serif;
}

.subscribe-title h3 {
  font-family: 'Bai Jamjuree', sans-serif;
}

.text-white {
  font-family: 'Bai Jamjuree', sans-serif;
}

.main-menu nav>ul>li>a {
  font-family: 'Bai Jamjuree', sans-serif !important;
}

.alert {
  font-family: 'Bai Jamjuree', sans-serif !important;
}

.btn {
  font-family: 'Bai Jamjuree', sans-serif !important;
}



.btn-alarming {
  font-family: 'Bai Jamjuree', sans-serif;
  padding: 20px;
  color: white;
  background-color: red;
}



.btn-stand-by {
  font-family: 'Bai Jamjuree', sans-serif;
  padding: 20px;
  color: white;
  background-color: green;
}



.admin-banner{
  height: 400px;

}

.first-content{
  margin-top:300px !important;
}

.sub-content{
  margin-top:200px !important;
}

.text-black{
  color: black;
}
.text-red{
  color: red;
}
.text-green{
  color:#98FF98;
}
.text-blue{
  color: #000080;
}
.breadcrumb-area{
  background-color: #000080;
}

.bg-green{
  background-color: #00b863 !important;
  color: white !important;
}

.bg-red{
  background-color: #ef1f1f !important;
  color: white !important;
}

.bg-black{
  background-color: black;
  color: white;
}

b, strong{
  font-family: 'Bai Jamjuree', sans-serif;
}
.p-inputswitch {
  width: 120px !important; /* Width of the switch */
  height: 60px !important; /* Height of the switch */
}

.p-inputswitch .p-inputswitch-slider {
  width: 120px !important; /* Width of the slider */
  height: 60px !important; /* Height of the slider */
  background-color: black !important; /* Color of the slider when OFF */
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: red !important; /* Color of the slider when ON */
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(90px) !important; /* Adjust to ensure proper movement */
}


@media screen  and (max-width: 600px) {
  .admin-banner{
      height: 200px;
  }
  .first-content{
      margin-top:100px !important;
  }
  .sub-content{
    margin-top:0px !important;
  }
}
</style>